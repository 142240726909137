<template>
  <article class="digitalizador" >
    <section v-bind:style="{ opacity: loading ? 0 :1 }">
      <aside :style="{ flexBasis: asideWidth + '%' }">
        <div>
          <h3>Servicio farmacéutico Rionegro</h3>
          <article class="fecha">
            <ul>
              
              <li>
                <div>
                  <v-text-field
                    v-model="fecha"
  
  
                    label="Fecha Formulación"
                    hide-details
                    required
                  >
                  </v-text-field>
                </div>
              </li>
            </ul>
          </article>
          <!-- <article class="entidad">
            <ul>
              
              <li>
                <div>
                  <v-text-field
                    v-model="entidad_formulante"
  
  
                    label="Entidad"
                    hide-details
                    required
                  >
                  </v-text-field>
                  
                </div>
              </li>
            </ul>
          </article> -->
          <article class="paciente">
            <ul>
              <li>
                <div>
                  <h4>Paciente</h4>
                </div>
                <div>
                  <v-text-field
                    v-model="documento"
  
                    @input="onInputUsuario"
                    label="Documento"
                    hide-details
                    required
                  >
                  </v-text-field>
                </div>
                <div>
                  <v-text-field
                  v-model="nombre"
                  label="Nombre"
                  hide-details
                  required
                  disabled
                  ></v-text-field>
                </div>
                <div>
                  <v-text-field
                    v-model="estado"
  
  
                    label="Estado"
                    hide-details
                    required
                    disabled
                  >
                  </v-text-field>
                </div>
                <div class="icons">
                  <figure v-if="usuarioConfirmado" class="ico-check" title="Confirmado"><v-icon class="me-2">check</v-icon></figure>
                  <figure v-if="!usuarioConfirmado" title="Usuario no confirmado" class="ico-error"><v-icon class="me-2">priority_high</v-icon></figure>
                </div>
              </li>
            </ul>
          </article>
          <article class="medico">
            
            <ul>
              <li>
                <div>
                  <h4>Médico</h4>
                </div>
                <div>
                  <v-text-field
                    v-model="documentoMedico"
                    @input="onInputMedico"
  
                    label="Documento"
                    hide-details
                    required
                  >
                  </v-text-field>
                </div>
                <div>
                  <v-text-field
                  v-model="nombreMedico"
                  label="Nombre"
                  hide-details
                  required
  
                  :disabled="isDisabledMedico"
                  ></v-text-field>
                </div>
                <div class="icons">
                  <figure v-if="medicoConfirmado" class="ico-check" title="Confirmado"><v-icon class="me-2">check</v-icon></figure>
                  <figure v-if="!medicoConfirmado" title="Médico no confirmado" class="ico-warning"><v-icon class="me-2">warning</v-icon></figure>
                </div>
                <div class="icons" v-if="!medicoConfirmado" @click="agregarMedico">
                  <figure class="ico-more" title="Agregar Médico"><v-icon class="me-2">add</v-icon></figure>
                </div>
              </li>
                
            </ul>
          </article>
          <article class="ips">
            
            <ul>
              <li>
                <div>
                  <h4>IPS</h4>
                </div>
                <div>
                  <v-text-field
                    v-model="pagador"
                    @input="onInputIps"
  
                    label="NIT Ips"
                    hide-details
                    required
                  >
                  </v-text-field>
                </div>
                <div>
                  <v-text-field
                  v-model="ips"
                  label="Nombre Ips"
                  hide-details
                  required
  
                  :disabled="isDisabledIps"
                  ></v-text-field>
                </div>
                <div class="icons">
                  <figure v-if="ipsConfirmado" class="ico-check" title="Confirmado"><v-icon class="me-2">check</v-icon></figure>
                  <figure v-if="!ipsConfirmado" title="Médico no confirmado" class="ico-warning"><v-icon class="me-2">warning</v-icon></figure>
                </div>
                <!-- <div class="icons" v-if="!ipsConfirmado" @click="agregarMedico">
                  <figure class="ico-more" title="Agregar Médico"><v-icon class="me-2">add</v-icon></figure>
                </div> -->
              </li>
                
            </ul>
          </article>
          <article class="diagnostico">
            <h4>Diagnóstico</h4>
            <ul>
              <li v-for="(diagnostico, index) in diagnosticos" :key="index">
                <div>
                  <v-text-field
                    v-model="diagnostico.codigoCIE"
                    
                    @input="onInputDiagnostico(index)"
                    label="Código CIE"
                    hide-details
                    required
                  >
                  </v-text-field>
                </div>
                <div>
                  <v-text-field
                  v-model="diagnostico.descripcionCIE"
                  label="Descripción"
                  hide-details
                  required
                  disabled
                  ></v-text-field>
                </div>
                <div class="icons">
                  <figure v-if="diagnostico.confirmado" class="ico-check" title="Confirmado"><v-icon class="me-2">check</v-icon></figure>
                  <figure v-if="!diagnostico.confirmado" title="Diagnóstico no confirmado" class="ico-warning"><v-icon class="me-2">warning</v-icon></figure>
                </div>
              </li>
            </ul>
          </article>
          <article class="medicamentos">
            <h4>Medicamentos</h4>
            <ul>
              <li v-for="(medicamento, index) in medicamentos" :key="index">
                <div>
                  <v-text-field
                  v-model="medicamento.nombre"
                  label="Nombre"
                  hide-details
                  required
                  ></v-text-field>
                </div>
                <div>
                  <v-text-field
                    v-model="medicamento.cantidad"
  
  
                    label="Cantidad"
                    hide-details
                    required
                  >
                  </v-text-field>
                </div>
                <div>
                  <v-text-field
                    v-model="medicamento.vigencia"
  
  
                    label="Vigencia"
                    hide-details
                    required
                  >
                  </v-text-field>
                </div>
              </li>
              
              
            </ul>
          </article>
          
          
          <footer>
            <v-btn variant="elevated" color="primary" :disabled="!usuarioConfirmado && false" @click="generarExpediente()">
              Generar Expediente
            </v-btn>
            <!-- <v-btn variant="elevated" color="secondary" @click="digitalizar">
              Digitalizar
            </v-btn> -->
          </footer>
        </div>
      </aside>
      <div class="divider" @mousedown="startDrag"></div>
      <article :style="{ flexBasis: articleWidth + '%' }">
        <section>
          <div ref="viewer" class="viewer-container">
            <!-- <div id="pdf-viewer"></div> -->
            <img :src="imageBase64" alt="Image" />
          </div>
        </section>
        <footer>
        </footer>
      </article>
    </section>
    <div class="loading" v-if="loading">
      <div class="loadingio-spinner-spinner-2by998twmg8"><div class="ldio-yzaezf3dcmj">
        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
        </div></div>
        <h4>Extrayendo información de la formula</h4>
    </div>  
  </article>
</template>

<script>
import Vue from 'vue';
import { mapState, mapMutations } from "vuex";
// import PDFObject from 'pdfobject'
import imagenBase64 from './imagenBase64.json';
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';
import Swal from 'sweetalert2'

export default {
  name: 'Index',
  data: () => ({
    tab: null,
    rolesKeycloak: null,
    codigosEmpresas: [],
    dataEmpresas: [],
    usuario: "",
    name: "",

    documento:"",
    nombre:"",
    estado:"",
    documentoMedico:"",
    nombreMedico:"",
    pagador:"",
    ips:"",

    indicacion:"",
    observaciones:"",
    producto:"",
    cantidad:"",
    duracion:"",
    fecha:"",
    asideWidth: 50,  
    articleWidth: 50, 
    isDragging: false,
    diagnosticos: [],
    medicamentos: [],
    entidad_formulante:"",

    searchQuery: '',
    timeout: null,

    loading: true,
    viewer: null,
    isDisabledMedico: false,
    isDisabledIps:false,

    file1: "1655146978601.pdf",
    file2: "1655148110018.pdf",
    url: "https://0qvhf3ep8b.execute-api.us-east-1.amazonaws.com/prod?function=View&filename=",
    urlBase64: "https://0qvhf3ep8b.execute-api.us-east-1.amazonaws.com/prod?function=Base64&filename=",

    usuarioConfirmado: false,
    medicoConfirmado: false,
    ipsConfirmado: false,
    bodegaId: "",

    imageBase64: imagenBase64.imageBase64,
  
  }),
  computed: {
    ...mapState(["auth", "notify", "busy", "enterprise"]),
  },
  created() {

    const savedAsideWidth = localStorage.getItem('asideWidth');
    const savedArticleWidth = localStorage.getItem('articleWidth');

    if (savedAsideWidth && savedArticleWidth) {
      this.asideWidth = parseFloat(savedAsideWidth);
      this.articleWidth = parseFloat(savedArticleWidth);
    }
  },
  mounted() {
    this.usuario = this.auth.username;
    this.loading=true;
    this.name=this.$route.params.name;
    
    // this.empresasUsuario();
    // PDFObject.embed(this.url+this.file1, '#pdf-viewer')

    this.name = this.$route.query.name;

    if(this.name=="test"){
      this.loading= false;
      let analyzedText={
          "entidad_formulante": "VISION INTEGRADOS SAS",
          "fecha_formulacion": "2024/07/25",
          "diagnostico": [
              {
                  "codigo": "Ha39"
              },
              {
                  "codigo": "H048"
              }
          ],
          "productos_recetados": [
              {
                  "nombre": "CARBOXIMETILCELULOSA 5 % SAN",
                  "cantidad": "Descripción no especificada",
                  "vigencia": "Descripción no especificada"
              }
          ],
          "datos_medico": {
              "documento": "13-11883",
              "nombre": "SILVIA ROSA FERNANDEZ"
          },
          "datos_paciente": {
              "documento": "43209287",
              "nombre": "CANO GONZALEZ SANDRA PATRICIA"
          },
          "archivo": "/var/www/ocr/uploads/e99171ed-37d4-4e87-b40d-7cc50bae25e1.png"
      };
      this.createViewer();


      this.diagnosticos=analyzedText.diagnostico;
      this.medicamentos=analyzedText.productos_recetados;
      this.documento=analyzedText.datos_paciente.documento;
      
      this.documentoMedico=analyzedText.datos_medico.documento;
      this.nombreMedico=analyzedText.datos_medico.nombre;
      
      this.fecha=analyzedText.fecha_formulacion;
      this.entidad_formulante=analyzedText.entidad_formulante;
      
      this.$http
      .get("msa-process-file/api/afiliado/list?documento="+this.documento)
      .then((result) => {
        console.log("usuario: ", result);
        if(result.data && result.data.nombreCompleto){
          this.nombre= result.data.nombreCompleto;
          this.estado= result.data.estadoAfiliado;
          if(result.data.estadoAfiliadoValue=="A"){
            this.usuarioConfirmado= true;
          }
        }
      });
      
      
      this.$http
      .get("msa-process-file/api/medico/list?documento="+this.documentoMedico)
      .then((result) => {
        console.log("medico: ", result);
        if(result.data && result.data.nombreCompleto){
          this.nombreMedico= (result.data.nombreCompleto)?result.data.nombreCompleto:"";
          this.isDisabledMedico= (result.data.nombreCompleto)?true:false;
          this.medicoConfirmado= true;
        }
      });
      
      let solicitudes = this.diagnosticos.map(diagnostico => {
      return this.$http.get("msa-process-file/api/cie/list?cie=" + diagnostico.codigo)
          .then((result) => {
            console.log("diagnosticos: ", result);          
            if(result.data && result.data.descripcionCIE){
              result.data.confirmado=true;
              return result.data;
            }else{
              return{
                codigoCIE: diagnostico.codigo,
                descripcionCIE: "",
                confirmado: false
              };
            }          
          })
          .catch(err => {
            console.error("Error en solicitud para diagnostico: " + diagnostico, err);
          
          });
      });

      Promise.all(solicitudes).then(diagnosticos => {
        console.log(diagnosticos);
        this.diagnosticos=diagnosticos;
      });

      this.medicamentos.map((it)=>{
        console.log(it);      
        it.cantidad=(it.cantidad=="Descripción no especificada")?"":it.cantidad;
        it.vigencia=(it.vigencia=="Descripción no especificada")?"":it.vigencia;
      })
    }else{   
      this.name = this.name.split("-").join("/");
      console.log(this.name);
      this.getBodega();

      this.$http
        .get("msa-process-file/api/file/ocrformula?fileName="+this.name)
        .then((result) => {
          console.log(result);
          console.log(JSON.parse(result.data.uploadResponse));                    
          this.loading= false;                     
          this.imageBase64="data:image/png;base64," +result.data.base64Data;
          this.createViewer();


          let analyzedText=JSON.parse(result.data.uploadResponse);
          this.diagnosticos=analyzedText.diagnostico;
          this.medicamentos=analyzedText.productos_recetados;
          this.documento=analyzedText.datos_paciente.documento;
          
          this.documentoMedico=analyzedText.datos_medico.documento;
          this.nombreMedico=analyzedText.datos_medico.nombre;
          
          this.pagador=analyzedText.nitEntidadFormulante;
          this.ips=analyzedText.entidad_formulante;
          
          this.fecha=analyzedText.fecha_formulacion;
          this.entidad_formulante=analyzedText.entidad_formulante;
          
          this.$http
          .get("msa-process-file/api/afiliado/list?documento="+this.documento)
          .then((result) => {
            console.log("usuario: ", result);
            if(result.data && result.data.nombreCompleto){
              this.nombre= result.data.nombreCompleto;
              this.estado= result.data.estadoAfiliado;
              if(result.data.estadoAfiliadoValue=="A"){
                this.usuarioConfirmado= true;
              }
            }
          });
          
          
          this.$http
          .get("msa-process-file/api/medico/list?documento="+this.documentoMedico)
          .then((result) => {
            console.log("medico: ", result);
            if(result.data && result.data.nombres){
              this.nombreMedico= result.data.nombres+" "+result.data.apellidos;
              this.estadoMedico= true;
              this.medicoConfirmado= true;
            }
          });

          this.$http
          .get("msa-process-file/api/prestador/list?nit="+this.pagador)
          .then((result) => {
            console.log("Ips: ", result);
            if(result.data && result.data.nombrePrestador){
              this.ips= result.data.nombrePrestador;
              this.estadoIps= true;
              this.ipsConfirmado= true;
            }
          });
          
          let solicitudes = this.diagnosticos.map(diagnostico => {
          return this.$http.get("msa-process-file/api/cie/list?cie=" + diagnostico.codigo)
              .then((result) => {
                console.log("diagnosticos: ", result);          
                if(result.data && result.data.descripcionCIE){
                  result.data.confirmado=true;
                  return result.data;
                }else{
                  return{
                    codigoCIE: diagnostico.codigo,
                    descripcionCIE: "",
                    confirmado: false
                  };
                }          
              })
              .catch(err => {
                console.error("Error en solicitud para diagnostico: " + diagnostico, err);
              
              });
          });

          Promise.all(solicitudes).then(diagnosticos => {
            console.log(diagnosticos);
            this.diagnosticos=diagnosticos;
          });

          this.medicamentos.map((it)=>{
            console.log(it);      
            it.cantidad=(it.cantidad=="Descripción no especificada")?"":it.cantidad;
            it.vigencia=(it.vigencia=="Descripción no especificada")?"":it.vigencia;
          })
      });
    }

    
  },
  beforeDestroy() {
    if (this.viewer) {
      this.viewer.destroy();
    }
  },

  methods: {
    ...mapMutations([
      "updateAuth",
      "hideNotify",
      "showSuccess",
      "showBusy",
      "hideBusy",
      "setEnterprise"
    ]),
    async empresasUsuario() {
      const codEmpresas = await this.$http.get(`msa-setting/api/usem/relacion/${this.usuario}`)
        .then(({ data }) => this.codigosEmpresas = data)
        .catch(err => console.log(err))

      if (codEmpresas != []) {
        await this.$http.post('msa-setting/api/empresa/list', this.codigosEmpresas)
          .then(({ data }) => this.dataEmpresas = data)
          .catch(err => console.log(err))
      }
    },
    toEnterprise(enterprise) {
      this.auth.roles = this.rolesKeycloak;
      this.setEnterprise(enterprise)
    },
    redirectToFormulas() {
      this.$router.push({ path: '/modules/expediente/expedientes' }); 
    },
    getBodega() {
      this.$http.get(`msa-process-file/api/file/getBodegaUsuario`)
        .then(({ data }) => {
          console.log(data);
          this.bodegaId=data;
        })
        .catch(err => console.log(err))
    },
    generarExpediente() {
      let medicamentos=[];
      this.medicamentos.map((it)=>{
        medicamentos.push({
          ...it,
          nombreProducto: it.nombre
        })
      })
      let data={
          "documentoAfiliado": this.documento,
          "fechaFormulacion": convertDate(this.fecha),    
          "cie": this.diagnosticos[0].codigoCIE, 
          "codMipres": "",
          "bodega": {
              "bodegasId": {
                  "idEmpresa": 1,
                  "codigoBodega": this.bodegaId
              }
          },
          "tipoArchivo": "ATTACHMENT", 
          "documentoMedico": this.documentoMedico,
          "prestador": this.pagador,
          "nombreArchivo": this.name.substring(this.name.indexOf("/")+1,this.name.length),
          "detalleProductos": medicamentos
      }

      this.$http.post('msa-process-file/api/expediente/crearExpediente', data)
        .then((data) => {
          console.log(data);
          
          Swal.fire({
            title: 'Expedientes',
            text: 'El expediente ha sido almacenado.',
            icon: 'success',
            confirmButtonText: 'Aceptar'
          });
          this.$router.push({ path: '/modules/expediente/bandeja' }); 
        })
        .catch(err => console.log(err));

        function convertDate(fecha) {          
          const [day, month, year] = fecha.split('/');
          const fullYear = parseInt(year) < 50 ? '20' + year : '19' + year;
          return `${fullYear}-${month}-${day}`;
        }
    },
    digitalizar() {

      let url = this.urlBase64 + this.file1;
      console.log(url);
      const fileName = this.file1;

      this.$http
        .post("msa-pharmaceutical-services/getFile", fileName)
        .then((result) => {
          console.log(result);
          
        });
    },


    startDrag() {
      this.isDragging = true;
      document.addEventListener('mousemove', this.onDrag);
      document.addEventListener('mouseup', this.stopDrag);
    },
    onDrag(e) {
      if (this.isDragging) {
        const containerWidth = this.$el.offsetWidth; 
        const mouseX = e.clientX; 
        const newAsideWidth = ((mouseX / containerWidth) * 100) - 4;
        const newArticleWidth = 100 - newAsideWidth;

        if (newAsideWidth > 10 && newArticleWidth > 10) { 
          this.asideWidth = newAsideWidth;
          this.articleWidth = newArticleWidth;
        }
      }
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.onDrag);
      document.removeEventListener('mouseup', this.stopDrag);

      localStorage.setItem('asideWidth', this.asideWidth);
      localStorage.setItem('articleWidth', this.articleWidth);

      this.refreshViewer();
    },

    refreshViewer() {
      if (this.viewer) {
        this.viewer.update(); // Si la librería tiene un método de actualización
      }
    },

    agregarMedico() {
      this.medicoConfirmado=true;
    },

    onInputUsuario() {
      clearTimeout(this.timeout);
      
      this.timeout = setTimeout(() => {
        this.searchUsuario();
      }, 3000); 
    },

    searchUsuario() {
      console.log('Buscando:', this.documento);
      
      this.$http
      .get("msa-process-file/api/afiliado/list?documento="+this.documentoMedico)
      .then((result) => {
        console.log("usuario: ", result);
        if(result.data && result.data.nombreCompleto){
          this.nombre= result.data.nombreCompleto;
          this.estado= result.data.estadoAfiliado;
          if(result.data.estadoAfiliadoValue=="A"){
            this.usuarioConfirmado= true;
          }
        }else{
          this.nombre= "";
          this.estado= "";
        }
      });
    },

    onInputMedico() {
      clearTimeout(this.timeout);
      
      this.timeout = setTimeout(() => {
        this.searchMedico();
      }, 3000); 
    },

    searchMedico() {
      console.log('Buscando:', this.documento);
      
      this.$http
      .get("msa-process-file/api/medico/list?documento="+this.documentoMedico)
      .then((result) => {
        console.log("Medico: ", result);
        if(result.data && result.data.nombres){
          this.nombreMedico= result.data.nombres+" "+result.data.apellidos;
          this.estadoMedico= true;
          this.medicoConfirmado= true;
        }else{
          this.nombreMedico= "";
          this.estadoMedico= false;
        }
      });
    },
    
    onInputIps() {
      clearTimeout(this.timeout);
      
      this.timeout = setTimeout(() => {
        this.searchIps();
      }, 3000); 
    },

    searchIps() {
      console.log('Buscando:', this.pagador);
      
      this.$http
      .get("msa-process-file/api/prestador/list?nit="+this.pagador)
      .then((result) => {
        console.log("Ips: ", result);
        if(result.data && result.data.nombrePrestador){
          this.ips= result.data.nombrePrestador;
          this.estadoIps= true;
          this.ipsConfirmado= true;
        }else{
          this.ips= "";
          this.estadoIps= false;
        }
      });
    },
    onInputDiagnostico(ind) {
      clearTimeout(this.timeout);
      
      this.timeout = setTimeout(() => {
        this.searchDiagnostico(ind);
      }, 3000); 
    },

    searchDiagnostico(ind) {
      console.log('Buscando:', this.diagnosticos[ind].codigoCIE);
      
      this.$http.get("msa-process-file/api/cie/list?cie=" + this.diagnosticos[ind].codigoCIE)
      .then((result) => {
        console.log("diagnosticos: ", result);
        result.data.confirmado=false;
        if(result.data && result.data.descripcionCIE){
          result.data.confirmado=true;
          console.log("diagnosticos: ", result.data.descripcionCIE);
          Vue.set(this.diagnosticos, ind, result.data);          
        }       
      })
      .catch(err => {
        console.error("Error en solicitud para diagnostico: " + this.diagnosticos[ind].codigoCIE, err);
      
      });
    },
    createViewer() {
        this.viewer = new Viewer(this.$refs.viewer, {
        viewed: () => {
          const containerWidth = this.viewer.element.parentNode.clientWidth;
          const containerHeight = this.viewer.element.parentNode.clientHeight;
          const imageWidth = this.viewer.image.width;
          const imageHeight = this.viewer.image.height;
          
          const zoomX = containerWidth / imageWidth;
          const zoomY = containerHeight / imageHeight;
          let optimalZoom = Math.min(zoomX, zoomY);
          optimalZoom=.5;
          this.viewer.zoomTo(optimalZoom);
          this.viewer.moveTo(0, 0); 
        },
        inline: true,
        navbar: true,
        toolbar: true,
        title: true,
      });
    }
  }
}
</script>

<style scoped>

ul, ol {
  list-style: none;
}

.viewer-toolbar{
  display: none;
}

.divider {
  width: 10px;
  cursor: col-resize;
  background-color: #ccc;
  height: 100%;
}

article.digitalizador {
  padding: 0;
  width: 100%;
}

article.digitalizador .icons{
  display: flex;
  justify-content: flex-end;
  position: relative;
  /* position: absolute; */
  /* right: 20px; */
  /* top: 0; */
  width: 60px;
}
article.digitalizador .icons figure{
  top: 11px;
  position: absolute;
}

article.digitalizador .ico-check{
  font-size: 1.2rem;
  padding: .2rem;
  width: 30px;
  height: 30px;
  
}

article.digitalizador .ico-check i{
  border-radius: 50%;
  border: 1px solid green;
  color: green;
  padding: .2rem;
}

article.digitalizador .ico-warning{
  font-size: 1.2rem;
  padding: .2rem;
  width: 30px;
  height: 30px;
  
}

article.digitalizador .ico-warning i{
  border-radius: 50%;
  border: 1px solid orange;
  color: orange;
  padding: .2rem;
}

article.digitalizador .ico-error{
  font-size: 1.2rem;
  padding: .2rem;
  width: 30px;
  height: 30px;
  
}

article.digitalizador .ico-error i{
  border-radius: 50%;
  border: 1px solid red;
  color: red;
  padding: .2rem;
}

article.digitalizador .ico-more{
  font-size: 1.2rem;
  padding: .2rem;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

article.digitalizador .ico-more i{
  border-radius: 50%;
  border: 1px solid var(--v-primary-base);
  color: var(--v-primary-base);
  padding: .2rem;
}


#viewer #toolbar{
  display: none !important;
}

article.digitalizador > section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin: 0;
  height: calc(100vh - 69px);
  
}


article.digitalizador > section > aside {
  width: 100%;
  height: calc(100vh - 69px);
  overflow-y: auto;
}

article.digitalizador > section > aside > div {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
}

article.digitalizador > section > aside > div > h3{
  padding-left: 25px;
  margin-top: 15px;
  margin-bottom: 15px;
  color: #0D47A1;
}

article.digitalizador > section > aside > div > article {
  margin-bottom: .4rem;
}

article.digitalizador > section > aside > div > article h4{
  padding-left: 5px;
  max-width: fit-content;
}

article.digitalizador > section > aside > div > article > ul {
  padding-left: 5px;
  
}

article.digitalizador > section > aside > div > article > ul > li {
  display: flex;
  justify-content: center;
  position: relative;
}

article.digitalizador > section > aside > div > article.paciente > ul > li > div:first-child ,
article.digitalizador > section > aside > div > article.medico > ul > li > div:first-child {
  width: 80px;
  min-width: 80px;
}
article.digitalizador > section > aside > div > article.medico > ul > li > div:nth-child(2),
article.digitalizador > section > aside > div > article.paciente > ul > li > div:nth-child(2){
  width: 20%
}
article.digitalizador > section > aside > div > article.paciente > ul > li > div:nth-child(3){
  width: 60%
}
article.digitalizador > section > aside > div > article.medico > ul > li > div:nth-child(3){
  width: 80%
}
article.digitalizador > section > aside > div > article.paciente > ul > li > div:nth-child(4){
  width: 20%
}


article.digitalizador > section > aside > div > article.ips > ul > li > div:first-child ,
article.digitalizador > section > aside > div > article.medico > ul > li > div:first-child {
  width: 80px;
  min-width: 80px;
}
article.digitalizador > section > aside > div > article.medico > ul > li > div:nth-child(2),
article.digitalizador > section > aside > div > article.ips > ul > li > div:nth-child(2){
  width: 40%
}
article.digitalizador > section > aside > div > article.ips > ul > li > div:nth-child(3){
  width: 60%
}
article.digitalizador > section > aside > div > article.medico > ul > li > div:nth-child(3){
  width: 80%
}
article.digitalizador > section > aside > div > article.ips > ul > li > div:nth-child(4){
  width: 20%
}

article.digitalizador > section > aside > div > article.diagnostico > ul > li > div:nth-child(1){
  width: 20%
}
article.digitalizador > section > aside > div > article.diagnostico > ul > li > div:nth-child(2){
  width: 80%
}

article.digitalizador > section > aside > div > article.medicamentos > ul > li > div:nth-child(1){
  width: 70%
}
article.digitalizador > section > aside > div > article.medicamentos > ul > li > div:nth-child(2){
  width: 15%
}
article.digitalizador > section > aside > div > article.medicamentos > ul > li > div:nth-child(3){
  width: 15%
}

article.digitalizador > section > aside > div > article.diagnostico{
  padding-left: 15px;
  margin-top: 20px;
}

article.digitalizador > section > aside > div > article.diagnostico .icons figure{  
}


article.digitalizador > section > aside > div > article.medicamentos{
  padding-left: 15px;
  margin: 20px 0;
}
article.digitalizador > section > aside > div > article.fecha > ul > li > div {
  padding-left: 15px;
}
article.digitalizador > section > aside > div > article.entidad > ul > li > div {
  padding-left: 15px;
}

article.digitalizador > section > aside > div > article > ul > li > div {
  margin: 0 .5rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}
article.digitalizador > section > aside > div > article > ul > li > div > div:not(.icons){
  width: 100%;
}

article.digitalizador > section > aside > div footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-top: 1rem;
  margin-right: 10px;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

article.digitalizador > section > aside > div footer button{
  margin: 0 .2rem;
}

article.digitalizador > section > article {
  margin-left: 0;
  width: 100%;
  height: calc(100vh - 69px);
}

article.digitalizador > section > article h4 {
  margin-bottom: 1rem;
}

article.digitalizador > section > article section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 0.05rem solid #0d47a1;
  height: 100%;
  width: 100%;
}

article.digitalizador > section > article section > div:first-child{
  opacity: 0;
}

article.digitalizador > section > article section > div,
article.digitalizador > section > article section > div .pdfobject-container {
  width: 100%;
  height: 100%;
}

article.digitalizador > section > article section div h6 {
  font-size: 2rem;
  text-align: center;
}

article.digitalizador > section > article section div figure svg {
  font-size: 4rem;
}

article.digitalizador > section > article footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 1rem;
  margin-right: 30px;
}

.loading{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100vh - 53px);
  background-color: rgba(0,0,0,.5);
  z-index: 2;
}
.loading h4{
  width: 100%;
  text-align: center;
  color: white;
  font-size: 19px;
  margin-top: -20px;
  font-weight: 200;
}

@keyframes ldio-yzaezf3dcmj {
  0% { opacity: 1 }
  100% { opacity: 0 }
}
.ldio-yzaezf3dcmj div {
  left: 96px;
  top: 45px;
  position: absolute;
  animation: ldio-yzaezf3dcmj linear 1s infinite;
  background: #0d47a1;
  width: 8px;
  height: 18px;
  border-radius: 3.42px / 3.42px;
  transform-origin: 4px 55px;
}.ldio-yzaezf3dcmj div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9375s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(2) {
  transform: rotate(22.5deg);
  animation-delay: -0.875s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(3) {
  transform: rotate(45deg);
  animation-delay: -0.8125s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(4) {
  transform: rotate(67.5deg);
  animation-delay: -0.75s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(5) {
  transform: rotate(90deg);
  animation-delay: -0.6875s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(6) {
  transform: rotate(112.5deg);
  animation-delay: -0.625s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(7) {
  transform: rotate(135deg);
  animation-delay: -0.5625s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(8) {
  transform: rotate(157.5deg);
  animation-delay: -0.5s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(9) {
  transform: rotate(180deg);
  animation-delay: -0.4375s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(10) {
  transform: rotate(202.5deg);
  animation-delay: -0.375s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(11) {
  transform: rotate(225deg);
  animation-delay: -0.3125s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(12) {
  transform: rotate(247.5deg);
  animation-delay: -0.25s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(13) {
  transform: rotate(270deg);
  animation-delay: -0.1875s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(14) {
  transform: rotate(292.5deg);
  animation-delay: -0.125s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(15) {
  transform: rotate(315deg);
  animation-delay: -0.0625s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(16) {
  transform: rotate(337.5deg);
  animation-delay: 0s;
  background: #0d47a1;
}
.loadingio-spinner-spinner-2by998twmg8 {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}
.ldio-yzaezf3dcmj {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-yzaezf3dcmj div { box-sizing: content-box; }
</style>